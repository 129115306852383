// extracted by mini-css-extract-plugin
export var topLine = "contact-module--topLine--Iw+QK";
export var bottomLine = "contact-module--bottomLine--VejBA";
export var contactTopImg = "contact-module--contactTopImg--sAI9Q";
export var contactTopImgDesc = "contact-module--contactTopImgDesc--4TJqn";
export var contactAdressMap = "contact-module--contactAdressMap--62GQa";
export var contactAdress = "contact-module--contactAdress--mvnQg";
export var contactAdressTitle = "contact-module--contactAdressTitle--s62D4";
export var contactAdressDesc = "contact-module--contactAdressDesc--JQqGV";
export var contactFormBtn = "contact-module--contactFormBtn--ILdfE";
export var contactBottomImg = "contact-module--contactBottomImg--3grBz";
export var contactBottomImgDesc = "contact-module--contactBottomImgDesc--fJmwj";